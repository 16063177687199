<template>
  <el-dialog
    title="提示"
    width="500px"
    append-to-body
    v-loading="submitLoading"
    :show-close="false"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="closeLog">
    <div slot="title" class="log-title">
      <i class="el-icon-warning"></i>
      <span>解散包裹</span>
    </div>
    <div class="log-radio">
      <div class="radio-item" @click="dissolveType = '1'">
        <span class="circle-radio">
          <span v-if="dissolveType === '1'"></span>
        </span>
        <span>保留包裹货品的拣货数量，可继续拣货后再去操作封包</span>
      </div>
      <div class="radio-item" @click="dissolveType = '2'">
        <span class="circle-radio">
          <span v-if="dissolveType === '2'"></span>
        </span>
        <span>清空包裹货品的拣货数量，本包裹的货品需要重新拣货</span>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="closeLog">取 消</el-button>
      <el-button type="danger" :loading="submitLoading" @click="sureLog">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {pdaDissolveParcel, returnPdaDissolveParcel} from "@/libs/http/modules/erp";
export default {
  props: {
    invoiceType: {
      type: String,
      default: () => ''
    },
    packageInfo: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      dialogVisible: true,
      dissolveType: '1',
      submitLoading: false
    }
  },
  methods: {
    closeLog(){
      this.dialogVisible = false
      this.$emit('closeLog')
    },
    sureLog(){
      this.submitLoading = true
      if(this.invoiceType === 'TCD'){
        returnPdaDissolveParcel({
          type: this.dissolveType,
          id: this.packageInfo.id
        }).then(data => {
          this.submitLoading = false
          this.dialogVisible = false
          this.$emit('closeLog', 'refresh')
        }).catch(error => {
          this.submitLoading = false
          this.$message({ type: 'error', message: error, duration: 2000, showClose: true })
        })
      }
      if(this.invoiceType === 'DCD'){
        pdaDissolveParcel({
          type: this.dissolveType,
          id: this.packageInfo.id
        }).then(data => {
          this.submitLoading = false
          this.dialogVisible = false
          this.$emit('closeLog', 'refresh')
        }).catch(error => {
          this.submitLoading = false
          this.$message({ type: 'error', message: error, duration: 2000, showClose: true })
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.log-title{
  display: flex;
  align-items: center;
  font-size: 18px;
  i{
    font-size: 20px;
    margin-right: 10px;
    color: rgb(250,140,22);
  }
}
.log-radio{
  margin-top: -20px;
}
.radio-item{
  display: flex;
  font-size: 16px;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
  .circle-radio{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border: 1px solid #aaa;
    border-radius: 50%;
    margin-right: 10px;
    span{
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #0B83F3;
    }
  }
}
</style>
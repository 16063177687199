<template>
    <!-- 调拨单-收 -->
    <div>
        <div class="dept-retreat">
            <div class="grid-box">
                <div class="right-box">
                    <div>
                        <basic-button
                            :disabled="displayRule('check')"
                            @click="delvoidList"
                            type="primary"
                            :loading="loading"
                            icon-class="acceptance"
                            style="width: 200px"
                        >
                            开始验收
                        </basic-button>
                    </div>
                </div>
                <grid-manager :option="gridOption"></grid-manager>
            </div>
            <SearchFormItem
                :options="options"
                @searchHandel="sureSharch"
                @getState="getFormState"
                ref="SearchFormItemIns"
            ></SearchFormItem>
        </div>
        <el-dialog
            width="40%"
            append-to-body
            title="请选择包裹号"
            custom-class="pack-dialog"
            :visible.sync="packDialogShow"
            @close="packDialogShow = false">
            <el-table
                border
                :data="packOptions"
                :row-class-name="tableRowClassName"
                :header-cell-style="{
                    color: '#555',
                    padding: '5px 0',
                    background: '#e8e8e8'
                }"
                @row-click="handleSelectionChange">
              <el-table-column label="选择" align="center" width="50" class="radios">
                <template slot-scope="scope">
                  <el-radio class="radios" v-model="packageId" :label="scope.row.packageId" :disabled="scope.row.enableCheck === 'Y' ? false : true"></el-radio>
                </template>
              </el-table-column>
              <el-table-column prop="packageSn" label="包裹号" align="center"></el-table-column>
              <el-table-column prop="expressSn" label="包裹快递号" align="center"></el-table-column>
              <el-table-column prop="logisticsName" label="物流公司" align="center"></el-table-column>
              <el-table-column prop="state" label="验收状态" align="center">
                <template slot-scope="scope">
                  {{ scope.row.state === '0' ? '未验收' : scope.row.state === '1' ? '验收中' : '已验收' }}
                </template>
              </el-table-column>
              <el-table-column prop="goodsNum" label="货品件数" align="center"></el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer">
              <el-button type="primary" size="small" @click="packConfirm">确 定</el-button>
            </div>
        </el-dialog>
        <ShopDetailsStoreOut
            v-if="detailDrawer"
            :modalParams="modalParams"
            @close="detailDrawer = false"
            type="1"
        ></ShopDetailsStoreOut>
        <CheckAcceptPackDrawer
            v-if="CheckAccepting"
            :modalParams="modalParams"
            @close="closePackDrawer"
            @refetch="sureSharch"
        ></CheckAcceptPackDrawer>
    </div>
</template>

<script>
import { columnData, options } from './conFigtrans'
import SearchFormItem from '@/components/SearchFormItem/SearchFormItem'
import { marketAllotQueryMarketAllotList, erp2MarketAllotQueryInvoicePackage } from '@/libs/http/modules/erp.js'
import ShopDetailsStoreOut from '../components/ShopDetailsStoreOut.vue'
import CheckAcceptPackDrawer from './components/CheckAcceptPackDrawer.vue'
export default {
    name: '',
    components: {
        SearchFormItem,
        ShopDetailsStoreOut,
        CheckAcceptPackDrawer
    },
    data() {
        return {
            detailDrawer: false,
            modalParams: null,
            loading: false,
            packDialogShow: false,
            packageId: '',
            packOptions: [],
            checkedList: [],
            gridOption: {
                width: '80vw - 345px',
                height: '85.5vh',
                gridManagerName: 'transferSlipinputEnter',
                firstLoading: true,
                isCombSorting: true,
                supportCheckbox: true,
                supportAutoOrder: false,
                useTrFocus: true,
                lineHeight: '20px',
                supportPrint: false,
                checkboxConfig: {
                    fixed: 'left',
                    useRowCheck: true
                },
                columnData,
                ajaxData: this.fetch,
				        ajaxError: this.ajaxError,
                supportAjaxPage: true,
                cellDblClick: this.rowdbClick,
                checkedAfter: this.checkedAfter,
                dataKey: 'items',
                totalsKey: 'totalItem'
            },
            options,
            queryKey: {},
            CheckAccepting: false
        }
    },
    methods: {
        checkedAfter(a) {
            this.checkedList = a
        },
        rowdbClick(row, rowIndex, colIndex) {
            if (colIndex == 1 || colIndex == 0) return
            this.showDetails(row)
        },
        displayRule(type) {
            let rule = true
            let list = this.checkedList
            if (list && list.length) {
                switch (type) {
                    case 'check':
                        rule = !(this.checkedList.length === 1 && list.every((i) => ['9', '11'].includes(i.state)))
                        break
                }
            }
            return rule
        },
        delvoidList() {
            if(this.checkedList[0].rfidInvoicePack === 'Y' && this.checkedList[0].rfidAllotInputSwitch === 'Y'){
              this.$confirm(`本单已通过RFID芯片进行出货，并且店铺已经开启了使用RFID芯片收货。请使用盘点机对本单进行收货！`, '提示', {
                showClose: false,
                showCancelButton: false,
                closeOnClickModal: false,
                confirmButtonClass: false,
                confirmButtonText: '知道了'
              }).then(res=>{

              }).catch(()=>{})
              return
            }
            let reqData = {
                id: this.checkedList[0].id
            }
            erp2MarketAllotQueryInvoicePackage(reqData).then((data) => {
              if(data && data.itemList){
                if (data.itemList.length > 1) {
                  this.packageId = ''
                  this.packOptions = data.itemList
                  this.packDialogShow = true
                } else {
                  this.modalParams = data.itemList[0]
                  this.CheckAccepting = true
                }
              }
            }).catch((err) => {
                this.$message({ type: 'error', message: err, duration: 2000, showClose: true })
            })
        },
        getFormState(e) {
            GridManager.resetLayout(this.gridOption.gridManagerName, e ? '80vw - 345px' : '80vw - 40px', '83vh')
        },
        refresh(e) {
            GridManager.refreshGrid(this.gridOption.gridManagerName, true)
        },
        showDetails(row) {
            this.detailDrawer = true
            this.modalParams = row
        },
        sureSharch(e) {
            this.queryKey = e
            GridManager.refreshGrid(this.gridOption.gridManagerName, true)
        },
        fetch(params) {
            GridManager.setCheckedData(this.gridOption.gridManagerName, [])
            this.checkedList = []
            let reqData = {
                page: params.pageData.cPage,
                pageSize: params.pageData.pSize,
                inMarketIds: [this.$cache.local.get('marketingUnitId')],
                source:'1'
            }
            if (!this.queryKey?.state?.length) {
              reqData['state'] = ['9', '10', '11']
            }
            for (let key in this.queryKey) {
                if (!['createTime', 'updateTime', 'invoicesNoList'].includes(key)) {
                    reqData[key] = this.queryKey[key]
                }

                if (this.queryKey.updateTime && this.queryKey.updateTime.length) {
                    let [srart, end] = this.queryKey.updateTime
                    reqData['updateStartTime'] = `${srart} 00:00:00`
                    reqData['updateEndTime'] = `${end} 23:59:59`
                }
                if (this.queryKey.createTime && this.queryKey.createTime.length) {
                    let [srart, end] = this.queryKey.createTime
                    reqData['createStartTime'] = `${srart} 00:00:00`
                    reqData['createEndTime'] = `${end} 23:59:59`
                }
                if (this.queryKey.invoicesNoList) {
                    reqData['invoicesNoList'] = this.queryKey.invoicesNoList.trim().split(/[ ]+/)
                }
            }
            this.$parent.quantiting()
            return marketAllotQueryMarketAllotList(reqData)
        },
        ajaxError(e){
          this.$message({type: 'error', message: e, duration: 2000, showClose: true})
        },

        handleSelectionChange(row) {
          if(row.enableCheck === 'N') {
            return this.$message.warning('该包裹已验收，无法选择！')
          }
          this.packageId = row.packageId
        },
        tableRowClassName({ row, columnIndex }) {
          if(row.enableCheck === 'N') {
            return 'warning-row'
          }
          return 'unwarning-row'
        },
        closePackDrawer(e) {
            this.CheckAccepting = false
            if (e) {
                GridManager.refreshGrid(this.gridOption.gridManagerName, true)
            }
        },
        packConfirm() {
            if (!this.packageId) {
              return this.$message.error('请选择包裹号')
            }
            this.CheckAccepting = true
            this.modalParams = { packageId: this.packageId }
            this.packDialogShow = false
        }
    }
}
</script>

<style lang="scss" scoped>
.dept-retreat {
    display: flex;
    padding: 10px;
    .grid-box {
    }
    .right-box {
        display: flex;
        justify-content: space-between;
    }
    .search-form-r {
    }
}

::v-deep .radios .el-radio__label{
  display: none;
}
::v-deep .el-table .warning-row {
  color: #b0afac !important;
  background: #f5f7fa!important;
}
::v-deep .el-table .unwarning-row {
  color: red !important;
}
</style>

<template>
    <el-drawer size="90%" :visible.sync="dialogVisible" :wrapperClosable="false" @close="close" :show-close="false">
        <section slot="title" class="dialog-top">
            <p>{{ '单据详情' }}</p>
            <div style="display: flex">
                <div class="sub-box" @click="checkGet()" v-if="type == 1 && [9, 10, 11].includes(+modalParams.state)">
                    <svg-icon class-name="size-icon" icon-class="acceptance" /> 开始验收
                </div>
                <div class="sub-box" @click="checkAcceptClick" v-if="type == 2 && [4, 5].includes(+modalParams.state)">
                    <svg-icon class-name="size-icon" icon-class="picking" /> 开始拣货
                </div>
                <i class="el-icon-close" @click="dialogVisible = false"></i>
            </div>
        </section>
        <section v-loading="loading" style="padding-bottom: 20px">
            <section class="step">
                <!-- <el-steps :active="scheduleState" finish-status="finish" process-status="wait" align-center>
                    <el-step
                        v-for="(item, index) in scheduleInfoList"
                        :key="item.sort"
                        :title="item.invoicesName"
                        :status="item.hasNowStep === 'N' ? 'wait' : ''"
                    >
                        <div slot="description" v-if="item.hasNowStep === 'Y'">
                            <div>{{ item.startTime }}</div>
                            <div v-if="item.consumingTime && index > 0" style="color: #999">
                                耗时：{{ item.consumingTime }}
                            </div>
                            <span v-if="item.timeout" style="color: red">超时：{{ item.overTime }}</span>
                        </div>
                    </el-step>
                </el-steps> -->
                <Timeline :timeList="scheduleInfoList"></Timeline>
            </section>
            <section class="section-card">
                <h3 class="section-title">
                    <span></span>
                    <div>单据信息</div>
                </h3>
                <div class="section-value" v-if="invoicesData">
                    <el-descriptions :column="4" size="small" border style="width: 100%">
                        <el-descriptions-item>
                            <template slot="label"> 创建人 </template>
                            {{ invoicesData.createUserName }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 发货方 </template>
                            {{ invoicesData.outMarketName }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 收货方 </template>
                            {{ invoicesData.inMarketName }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <!-- {{
                                    drawerType == 2 || drawerType == 3
                                        ? '调拨数量'
                                        : drawerType == 5
                                        ? '退仓数量'
                                        : drawerType == 6
                                        ? '退供数量'
                                        : '铺货数量'
                                }} -->
                                调拨数量
                            </template>
                            {{ invoicesData.totalNum }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 出货数量 </template>
                            {{ invoicesData.outNum }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 入库数量 </template>
                            {{ invoicesData.inNum }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 出货差异数 </template>
                            {{ invoicesData.outNumDifference }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 入库差异数 </template>
                            <!-- {{ invoicesData.storageDifferenceCount }} -->
                            {{ invoicesData.inNumDifference }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 创建时间 </template>
                            {{ invoicesData.createTime }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 更新时间 </template>
                            {{ invoicesData.updateTime }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 最后修改人 </template>
                            {{ invoicesData.updateUserName }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 总耗时 </template>
                            {{ invoicesData.totalTime }}
                        </el-descriptions-item>
                        <el-descriptions-item v-if="invoicesData.goodsPrincipal && drawerType != 2">
                            <template slot="label"> 商品负责人 </template>
                            {{ invoicesData.goodsPrincipal }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 备注 </template>
                            {{ invoicesData.remark }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 差异出货原因 </template>
                            {{ invoicesData.difReason }}
                        </el-descriptions-item>
                    </el-descriptions>
                </div>
                <!-- <div class="detail-state">
          <img v-if="modalParams.state == 100" src="@/assets/images/erp/void.png" />
          <img v-if="modalParams.state == 103" src="@/assets/images/erp/sended.png" />
          <img v-if="modalParams.state == 104" src="@/assets/images/erp/orderpack.png" />
          <img v-if="modalParams.state == 105" src="@/assets/images/erp/orderpacked.png" />
          <img v-if="modalParams.state == 106" src="@/assets/images/erp/packing.png" />
          <img v-if="modalParams.state == 107" src="@/assets/images/erp/packed.png" />
          <img v-if="modalParams.state == 109" src="@/assets/images/erp/outed.png" />
          <img v-if="modalParams.state == 110" src="@/assets/images/erp/checked.png" />
          <img v-if="modalParams.state == 112" src="@/assets/images/erp/joined.png" />
        </div> -->
                <div class="detail-state" v-if="invoicesData">
                    <img v-if="invoicesData.state == 0" src="@/assets/images/erp/void.png" />
                    <!-- <img v-if="invoicesData.state == 2" src="@/assets/images/erp/submit.png" /> -->
                    <img v-if="invoicesData.state == 4" src="@/assets/images/erp/sended.png" />
                    <img v-if="invoicesData.state == 5" src="@/assets/images/erp/orderpack.png" />
                    <img v-if="invoicesData.state == 6" src="@/assets/images/erp/orderpacked.png" />
                    <img v-if="invoicesData.state == 7" src="@/assets/images/erp/packing.png" />
                    <img v-if="invoicesData.state == 8" src="@/assets/images/erp/packed.png" />
                    <img v-if="invoicesData.state == 9" src="@/assets/images/erp/outed.png" />
                    <img v-if="invoicesData.state == 10" src="@/assets/images/erp/checked.png" />
                    <!-- <img v-if="invoicesData.state == 12" src="@/assets/images/erp/ys-succes.png" /> -->
                    <img v-if="invoicesData.state == 14" src="@/assets/images/erp/joined.png" />
                    <!-- <img v-if="invoicesData.state == 13" src="@/assets/images/erp/grounding.png" /> -->
                    <!-- <img v-if="invoicesData.state == 11" src="@/assets/images/erp/ysing.png" /> -->
                </div>
            </section>

            <section class="section-card">
                <h3 class="section-title">
                    <span></span>
                    <div>商品列表</div>
                </h3>
                <div>
                    <SizeGroupTable
                        ref="sizeGroupTableIns"
                        :dataList="dataList"
                        :columns="newColumns"
                        :isEmpty="true"
                        :sizeInfoLabels="newSizeInfoLabels"
                        :footerOptions="newFooterOptions"
                        border
                    >
                        <template v-slot="{ row, item }">
                            <el-popover v-if="item.prop === 'goodsNo'" placement="right-start" trigger="click">
                                <el-image
                                    v-if="row.imageList && row.imageList.length"
                                    style="width: 400px; height: 400px"
                                    :src="row.imageList[0]"
                                    :preview-src-list="row.imageList"
                                >
                                </el-image>
                                <span slot="reference" class="text-link">{{ row.goodsNo }} </span>
                            </el-popover>
                        </template>
                    </SizeGroupTable>
                </div>
            </section>

            <section class="section-card" v-if="logisticsList.length">
                <h3 class="section-title">
                    <span></span>
                    <div>发货信息</div>
                </h3>
                <div style="margin: 20px">
                    <el-table
                        border
                        :data="logisticsList"
                        :header-cell-style="{
                            background: '#F3F4F7',
                            color: '#555',
                            padding: '5px 0'
                        }"
                        :cell-style="{ padding: '5px 0' }"
                        :span-method="arraySpanMethod"
                    >
                        <el-table-column prop="invoicesNo" label="单号" align="center"> </el-table-column>
                        <el-table-column prop="packageSn" label="包裹号" align="center">
                            <template slot-scope="scope">
                                <span
                                    @click="packageInfo(scope.row)"
                                    style="text-decoration: underline; color: #1890ff; cursor: pointer"
                                    >{{ scope.row.packageSn }}</span
                                >
                            </template>
                        </el-table-column>
                        <el-table-column prop="goodsNum" label="货品数量" align="center"></el-table-column>
<!--                        <el-table-column v-if="type != 2" prop="goodsNum" label="货品数量" align="center">-->
<!--                        </el-table-column>-->
<!--                        <el-table-column v-if="type == 2" prop="goodsNum" label="货品数量" align="center">-->
<!--                            <template slot-scope="scope">-->
<!--                                <span-->
<!--                                    @click="packageInfo(scope.row)"-->
<!--                                    style="text-decoration: underline; color: #1890ff; cursor: pointer"-->
<!--                                    >{{ scope.row.goodsNum }}</span-->
<!--                                >-->
<!--                            </template>-->
<!--                        </el-table-column>-->
                        <el-table-column prop="senderInfoName" label="发货方" align="center"> </el-table-column>
                        <el-table-column prop="receiveInfoName" label="收货方" align="center"> </el-table-column>
                        <el-table-column prop="logisticsName" label="物流公司" align="center"> </el-table-column>
                        <el-table-column prop="expressNo" label="快递单号" align="center"> </el-table-column>
                        <el-table-column prop="state" label="状态" align="center">
<!--                            <template slot-scope="scope">-->
<!--                                <el-tag v-if="scope.row.state == 0" effect="dark" type="danger">未揽收</el-tag>-->
<!--                                <el-tag v-if="scope.row.state == 1" effect="dark" type="warning">待发货</el-tag>-->
<!--                                <el-tag v-if="scope.row.state == 2" effect="dark" type="primary">运输中</el-tag>-->
<!--                                <el-tag v-if="scope.row.state == 3" effect="dark" type="primary">配送中</el-tag>-->
<!--                                <el-tag v-if="scope.row.state == 4" effect="dark" type="success">已妥投</el-tag>-->
<!--                            </template>-->
                          <template slot-scope="scope">
                            <el-tag effect="dark"  v-if="scope.row.logisticsName && scope.row.logisticsName.indexOf('京东') !== -1">
                              {{ scope.row.jdStateDesc ? scope.row.jdStateDesc : '待更新' }}
                            </el-tag>
                            <el-tag effect="dark" v-else :type="expressState[scope.row.state].type">
                              {{ scope.row.stateDesc }}
                            </el-tag>

                          </template>
                        </el-table-column>
                        <el-table-column prop="shipmentTime" label="发货时间" align="center"> </el-table-column>
                        <el-table-column prop="signTime" label="签收时间" align="center"> </el-table-column>
                        <el-table-column fixed="right" label="操作" width="220px" align="center">
                            <template slot-scope="scope" style="display: flex">
                                <div style="display: flex">
                                    <el-button @click="clickPackage(scope.row)" type="text" size="small">
                                      <span style="text-decoration: underline">查看包裹</span>
                                    </el-button>
                                    <el-button @click="handleClick(scope.row)" type="text" size="small">
                                        <span style="text-decoration: underline">物流轨迹</span>
                                    </el-button>
                                    <el-button @click="amendClick(scope.row)" type="text" size="small" v-if="scope.row.expressNo && type == 2">
                                        <span style="text-decoration: underline">修改信息</span>
                                    </el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </section>

            <section class="section-card" v-if="differList.length">
              <h3 class="section-title">
                <span></span>
                <div>收货差异信息</div>
              </h3>
              <div style="margin: 20px">
                <el-table
                    border
                    :data="differList"
                    :header-cell-style="{
                      background: '#F3F4F7',
                      color: '#555',
                      padding: '5px 0'
                    }"
                    :cell-style="{ padding: '5px 0' }">
                  <el-table-column prop="packageSn" label="包裹号" align="center"></el-table-column>
                  <el-table-column prop="receiveName" label="收货人" align="center"></el-table-column>
                  <el-table-column prop="checkCompleteTime" label="收货完成时间" align="center" width="200"></el-table-column>
                  <el-table-column prop="sendNum" label="货品数量" align="center"></el-table-column>
                  <el-table-column prop="sendEpcNum" label="出货标签数量" align="center"></el-table-column>
                  <el-table-column prop="discrepancyNum" label="差异" align="center"></el-table-column>
                  <el-table-column prop="sendDutyNum" label="发货方责任" align="center"></el-table-column>
                  <el-table-column prop="receiveDutyNum" label="收货方责任" align="center"></el-table-column>
                  <el-table-column prop="transportDutyNum" label="运输方责任" align="center"></el-table-column>
                  <el-table-column prop="state" label="状态" align="center">
                    <template slot-scope="scope">
                      <el-tag
                          effect="dark"
                          size="mini"
                          :type="scope.row.state === '0' ? 'danger' : 'success'">
                        {{scope.row.state === '0' ? '未定责' : '已定责'}}
                      </el-tag>
                    </template>
                  </el-table-column>
                  <el-table-column fixed="right" label="操作" width="150">
                    <template slot-scope="scope">
                      <el-button @click="showDifferDetail(scope.row)" type="text" size="small">
                        <span style="text-decoration: underline">查看详情</span>
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </section>

            <section class="section-card" v-if="invoiceInvalidApplyInfoList.length">
              <h3 class="section-title">
                <span></span>
                <div>作废申请记录</div>
              </h3>
              <div style="margin: 20px;">
                <el-table
                    border
                    :data="invoiceInvalidApplyInfoList"
                    :header-cell-style="{
                                        background: '#F3F4F7',
                                        color: '#555',
                                        padding: '5px 0'
                                    }"
                    :cell-style="{ padding: '5px 0' }">
                  <el-table-column prop="applyUserName" label="申请人" align="center" width="180"> </el-table-column>
                  <el-table-column prop="applyTime" label="申请时间" align="center" width="200"> </el-table-column>
                  <el-table-column prop="applyRemark" label="申请理由" align="center"> </el-table-column>
                  <el-table-column prop="state" label="状态" align="center" width="180">
                    <template slot-scope="scope">
                      <el-tag
                          effect="dark"
                          :type="scope.row.state == '0' ? 'warning' : scope.row.state == '1' ? 'success' : 'danger'">
                        {{scope.row.state == '0' ? '待审批' : scope.row.state == '1' ? '已通过' : '已拒绝'}}
                      </el-tag>
                    </template>
                  </el-table-column>
                  <el-table-column prop="checkUserName" label="审批人" align="center" width="200"> </el-table-column>
                  <el-table-column prop="checkTime" label="审批时间" align="center" width="200"> </el-table-column>
                </el-table>
              </div>
            </section>
            <packageAndEpc
                v-if="packageModule"
                :detailList="detailLists"
                @close="packageModule = false"
            ></packageAndEpc>
            <TrackDialog v-if="disloagModule" :expressNo="expressNo" @close="disloagModule = false"></TrackDialog>
            <AllotPickUp v-if="type == 2 && CheckAcceptModule" :modalParams="modalParams" @close="checkClose"></AllotPickUp>
            <CheckAcceptPackDrawer
                v-if="type == 1 && CheckAcceptModule"
                :modalParams="drawerModalParams"
                @close="checkpackClose"
            >
            </CheckAcceptPackDrawer>
            <printDeliveryOrder
                v-if="printDialog"
                @close="printDialog = false"
                type="2"
                :modalParams="rowData"
                :isPrint="false"
            ></printDeliveryOrder>
            <entryCourierInfo
                v-if="entryCourierDialog"
                invoiceType="DCD"
                :modalParams="modalParams"
                :updateInfo="details"
                @close="entryCourier">
            </entryCourierInfo>
            <differDetailDialog v-if="isShowDiffer" :differRows="differRows" @close="isShowDiffer = false" @updateDiffer="fetchDifferList"></differDetailDialog>
            <el-dialog
                width="40%"
                append-to-body
                title="请选择包裹号"
                custom-class="pack-dialog"
                :visible.sync="packDialogShow"
                @close="packDialogShow = false">
                <el-table
                  border
                  :data="packOptions"
                  :row-class-name="tableRowClassName"
                  :header-cell-style="{
                    color: '#555',
                    padding: '5px 0',
                    background: '#e8e8e8'
                  }"
                  @row-click="handleSelectionChange">
                  <el-table-column label="选择" align="center" width="50" class="radios">
                    <template slot-scope="scope">
                      <el-radio class="radios" v-model="packageId" :label="scope.row.packageId" :disabled="scope.row.enableCheck === 'Y' ? false : true"></el-radio>
                    </template>
                  </el-table-column>
                  <el-table-column prop="packageSn" label="包裹号" align="center"></el-table-column>
                  <el-table-column prop="expressSn" label="包裹快递号" align="center"></el-table-column>
                  <el-table-column prop="logisticsName" label="物流公司" align="center"></el-table-column>
                  <el-table-column prop="state" label="验收状态" align="center">
                    <template slot-scope="scope">
                      {{ scope.row.state === '0' ? '未验收' : scope.row.state === '1' ? '验收中' : '已验收' }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="goodsNum" label="货品件数" align="center"></el-table-column>
                </el-table>
                <div slot="footer" class="dialog-footer">
                  <el-button type="primary" size="small" @click="packConfirm">确 定</el-button>
                </div>
            </el-dialog>
        </section>
    </el-drawer>
</template>

<script>
import Timeline from '@/components/Timeline/index.vue'
import packageAndEpc from './packageEpcPh.vue'
import svgIcon from '@/components/SvgIcon/index'
import differDetailDialog from './differDetailDialog'
import TrackDialog from './trackDialog.vue'
import {
  erpMarketAllotQueryMarketAllotDetail,
  erp2MarketAllotQueryInvoicePackage,
  erp2OperatingInterceptExpress, queryDiscrepancyList
} from '@/libs/http/modules/erp.js'
import AllotPickUp from "../output/components/AllotPickUp.vue"
import CheckAcceptPackDrawer from '../input/components/CheckAcceptPackDrawer.vue'
import printDeliveryOrder from './printDeliveryOrder.vue'
import entryCourierInfo from './entryCourierInfo.vue'
import SizeGroupTable from '@/components/SizeGroupTable/index.vue'
import { columnObj, sizeInfoLabel, footerObj } from './tableConfig.js'

export default {
    name: 'ShopDetails',
    props: {
        modalParams: {
            type: Object,
            default: () => null
        },

        type: {
            type: String,
            default: '1'
        }
    },
    components: {
        TrackDialog,
        AllotPickUp,
        printDeliveryOrder,
        entryCourierInfo,
        CheckAcceptPackDrawer,
        SizeGroupTable,
        differDetailDialog,
        packageAndEpc,
        svgIcon,
        Timeline
    },
    data() {
        return {
            columnObj,
            sizeInfoLabel,
            footerObj,
            dialogVisible: false,
            CheckAcceptModule: false,
            entryCourierDialog: false,
            drawerType: 1,
            details: [],
            dataList: [],
            invoicesData: null,
            invoicesSnType: '',
            logisticsList: [],
            courierInfoDialog: false,
            scheduleInfoList: [],
            scheduleState: 2,
            disloagModule: false,
            rowData: null,
            printDialog: false,
            expressNo: null,
            packDialogShow: false,
            loading: false,
            packageId: '',
            packOptions: [],
            drawerModalParams: {},
          invoiceInvalidApplyInfoList:[],
          expressState: {
            0: {
              type: 'warning'
            },
            1: {
              type: 'warning'
            },
            2: {
              type: 'info'
            },
            3: {
              type: 'info'
            },
            4: {
              type: 'success'
            }
          },
          differList: [],
          differRows: null,
          isShowDiffer: false,
          packageModule: false,
          detailLists: [],
        }
    },
    computed: {
        newSizeInfoLabels() {
            return this.formatLabels(this.sizeInfoLabel['DB'])
        },
        newFooterOptions() {
            return this.footerObj['DB']
        },
        newColumns() {
            return this.columnObj['DDDB']
        }
    },
    mounted() {
        this.fetchDetail()
        this.fetchDifferList()
    },
    methods: {
        formatLabels(arr) {
            return arr.map((item, index) => {
                if (index === 0) {
                    item.disabled = false
                    item.value = this.scheduleState >= 1 ? true : false
                } else if ([1, 2].includes(index)) {
                    item.disabled = this.scheduleState >= 2 ? false : true
                    item.value = this.scheduleState >= 2 ? true : false
                } else if ([3, 4].includes(index)) {
                    item.disabled = this.scheduleState > 3 ? false : true
                    item.value = this.scheduleState > 3 ? true : false
                }
                return item
            })
        },
        checkClose(e) {
            this.CheckAcceptModule = false
            if (e) {
                this.$parent.refresh(e)
                this.modalParams.state = 6
                this.fetchDetail()
            }
            // this.$par('refresh', true)
            // this.$parent.refresh(e)
        },
        checkpackClose(e) {
            this.CheckAcceptModule = false
            if (e) {
                this.$parent.refresh(e)
                this.fetchDetail()
            }
        },
        clickPackage(row) {
          this.packageModule = true
          this.detailLists = row
        },
        packageInfo(row) {
            this.printDialog = true
            row.invoicesId = this.modalParams.invoicesId
            this.rowData = row
        },
        close() {
            this.dialogVisible = false
            this.$emit('close')
        },
        checkAcceptClick() {
            if(this.type == 2 && this.modalParams['rfidAllotOutSwitch'] === 'Y'){
              return this.$message({ type: 'error', message: '请用PDA拣货', duration: 2000, showClose: true })
            }
            this.CheckAcceptModule = true
        },
        fetchDetail() {
            this.loading = true
            this.dialogVisible = true
            erpMarketAllotQueryMarketAllotDetail({
                id: this.modalParams.id
            })
                .then((data) => {
                    this.loading = false
                    this.invoicesData = data.invoicesInfo
                    this.modalParams.state = data.invoicesInfo.state // 更新状态
                    this.invoicesSnType = data.invoicesInfo.invoicesSn.slice(0, 2) || 'PH'
                    this.$nextTick(() => {
                        this.dataList = data.goodsInfoList || []
                    })
                    this.invoiceInvalidApplyInfoList = data.invoiceInvalidApplyInfoList || []
                    this.logisticsList = data.logisticsList || []
                    this.scheduleInfoList = data.scheduleInfoList || []
                    this.scheduleState = Number(data.scheduleState)
                })
                .catch((err) => {
                    this.loading = false
                    this.$message({
                        type: 'error',
                        message: err,
                        duration: 2000,
                        showClose: true
                    })
                })
        },
        getSpanArr(data, spanKey) {
            let spanArr = [] //值
            let pos = '' //下标
            for (let i = 0; i < data.length; i++) {
                if (i === 0) {
                    spanArr.push(1)
                    pos = 0
                } else {
                    // 判断当前元素与上一个元素是否相同
                    if (data[i][spanKey] === data[i - 1][spanKey]) {
                        spanArr[pos] += 1
                        spanArr.push(0)
                    } else {
                        spanArr.push(1)
                        pos = i
                    }
                }
            }
            return spanArr
        },
        arraySpanMethod({ row, column, rowIndex, columnIndex }) {
            let currindex = [0, 3, 4, 5, 6, 7, 8, 9]
            if (currindex.indexOf(columnIndex) != -1) {
                let spanArr = this.getSpanArr(this.logisticsList, row.invoicesNo)
                const _row = spanArr[rowIndex]
                const _col = _row > 0 ? 1 : 0
                return {
                    rowspan: _row,
                    colspan: _col
                }
            }
        },
        handleClick(row) {
            row.invoicesId = this.modalParams.invoicesId
            this.expressNo = row
            this.disloagModule = true
        },

        amendClick(row) {
            if (row.type == '1') {
                this.$confirm(`是否取消快递单`, {
                    type: 'warning'
                })
                    .then(() => {
                        erp2OperatingInterceptExpress({
                            id: row.packageId
                        })
                            .then((data) => {
                                this.$message.success('取消成功')
                                this.$nextTick(() => {
                                    this.fetchDetail()
                                })
                                this.entryCourierDialog = true
                                this.details = row
                            })
                            .catch((err) => {
                                this.$message({
                                    type: 'error',
                                    message: err,
                                    duration: 2000,
                                    showClose: true
                                })
                            })
                    })
                    .catch(() => {})
            }
            if (row.type == '2') {
                this.entryCourierDialog = true
                const list = this.logisticsList.map(item=>{
                  return item.packageId
                })
                this.details = { ...row, packageIds: list }
            }
        },
        entryCourier(refetch) {
            this.entryCourierDialog = false
            if (refetch) {
                this.fetchDetail()
            }
        },

        checkGet() {
            if (this.type == 1) {
                if(this.modalParams['rfidInvoicePack'] === 'Y' && this.modalParams['rfidAllotInputSwitch'] === 'Y'){
                  this.$confirm(`本单已通过RFID芯片进行出货，并且店铺已经开启了使用RFID芯片收货。请使用盘点机对本单进行收货！`, '提示', {
                    showClose: false,
                    showCancelButton: false,
                    closeOnClickModal: false,
                    confirmButtonClass: false,
                    confirmButtonText: '知道了'
                  }).then(res=>{

                  }).catch(()=>{})
                  return
                }
                this.getPackOptions()
            } else {
                this.CheckAcceptModule = true
            }
        },

        handleSelectionChange(row) {
          if(row.enableCheck === 'N') {
            return this.$message.warning('该包裹已验收，无法选择！')
          }
          this.packageId = row.packageId
        },
        tableRowClassName({ row, columnIndex }) {
          if(row.enableCheck === 'N') {
            return 'warning-row'
          }
          return 'unwarning-row'
        },
        packConfirm() {
            if (!this.packageId) {
              return this.$message.error('请选择包裹号')
            }
            this.packDialogShow = false
            this.CheckAcceptModule = true
            this.drawerModalParams = { packageId: this.packageId }
        },
        getPackOptions() {
            let reqData = {
                id: this.modalParams.id
            }
            erp2MarketAllotQueryInvoicePackage(reqData).then((data) => {
                if (data.itemList.length > 1) {
                    this.packageId = ''
                    this.packOptions = data.itemList
                    this.packDialogShow = true
                } else {
                    this.drawerModalParams = data.itemList[0]
                    this.CheckAcceptModule = true
                }
            }).catch((err) => {
                this.$message({ type: 'error', message: err, duration: 2000, showClose: true })
            })
        },
        fetchDifferList(){
          queryDiscrepancyList({
            page: '1',
            pageSize: '100',
            queryText: this.modalParams.sn
          }).then(res=>{
            console.log(res)
            this.differList = res.items || []
          }).catch(error=>{})
        },
        showDifferDetail(row){
          this.differRows = row
          this.isShowDiffer = true
        }
    }
}
</script>

<style lang="scss" scoped>
.dialog-top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        font-size: 18px;
        color: #333;
    }

    div i {
        cursor: pointer;
        font-size: 22px;
        margin-left: 20px;
        float: right;
        margin-top: 8px;
    }

    .sub-box {
        color: black;
        margin: 10px 15px;
        padding: 10px 50px;
        background: #c6e7ff;
        display: flex;
        justify-content: center;
        align-content: center;
        cursor: pointer;
        border-radius: 3px;

        img {
            width: 20px;
            margin-right: 10px;
        }
    }
}

.step {
    width: 100%;
    padding-bottom: 50px;
}

.section-card {
    position: relative;
    margin-bottom: 30px;
    .text-link {
        color: #1890ff;
        cursor: pointer;
    }

    .section-title {
        font-size: 18px;
        color: #333;
        font-weight: normal;
        display: flex;
        align-items: center;

        span {
            margin-top: -2px;
            width: 4px;
            height: 18px;
            background: #067cf2;
            margin-right: 16px;
            border-radius: 0 2px 2px 0;
        }
    }

    > div {
        margin: 20px;
    }

    .detail-state {
        position: absolute;
        right: 20px;
        top: -50px;

        img {
            width: 80px;
        }
    }

    .section-value {
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;

        > div {
            width: calc(100% / 3);
            margin-bottom: 10px;
        }
    }
}

.size-icon {
    margin-right: 5px;
    margin-top: 3px;
}


::v-deep .radios .el-radio__label{
  display: none;
}
::v-deep .el-table .warning-row {
  color: #b0afac !important;
  background: #f5f7fa!important;
}
::v-deep .el-table .unwarning-row {
  color: red !important;
}
</style>
